import React from "react"
import { Link, graphql } from "gatsby"
import 'twin.macro'

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title="404: Not Found" />
      <h1 tw="text-center">Not Found</h1>
      <p tw="text-center">Oh no! This page doesn't seem to exist. Here are some popular places to go from here:</p>
      <p tw="text-center"><Link to ="/">Homepage</Link></p>
      <p tw="text-center"><Link to ="/about">Meet us and learn our story</Link></p>
      <p tw="text-center"><Link to ="/courses">Browse our courses</Link></p>
      <p tw="text-center"><Link to ="/blog">Check out the blog</Link></p>

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
